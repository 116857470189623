.notification-item {
  background: #193052ee;
  border-radius: 4px;
  transform: translateY(500px);
  opacity: 0;
  transition: all .2s ease-in !important;

  &.in {
    transition: all .2s ease-in;
    transform: translateY(0);
    opacity: 1;
  }

  &.out {
    transition: all .5s ease-out;
    transform: translateY(-100px);
    opacity: 0;
  }
}

.notification-title {
  @include regular;
  font-size: 16px;
  color: white;
  line-height: 1.57;
}
.notification-message {
  @include regular;
  font-size: 14px;
  color: white;
  line-height: 1.57;
}

.notification-warning {
  border-left-color: #fda700;
}
@import 'assets/styles/_mixins.scss';

.period-select {
  padding: 40px 50px 38px;

  &__title {
    font-size: 24px;
    color: #1e1e1e;
    margin-bottom: 20px;
    @include regular;
  }

  &__weekdays {
    margin-bottom: 14px;
  }
  &__daytime {
    margin-bottom: 27px;
  }
  &__dates-range {
    margin-bottom: 20px;
  }

  &__dates-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
  }

  &__save-button {
    width: calc(50% - 17px);
    height: 40px;
    font-size: 14px;
    color: white;
    background-color: var(--blue-color);
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid var(--blue-color);
    @include button-hover;
  }

  &__reset-button {
    width: calc(50% - 17px);
    height: 40px;
    font-size: 14px;
    color: var(--blue-color);
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid var(--blue-color);
  }
}

@media screen and (max-width: 800px) {
  .period-select {
    width: auto;
    padding: 20px;

    &__title {
      font-size: 20px;
    }
  }
}
@import "assets/styles/_mixins.scss";

.popup {
  position: relative;
  min-width: 400px;
  max-width: 800px;
  background: white;

  &__close-button {
    @include reset-button-styles;
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__close-button-icon {
    fill: white;
    fill-opacity: 0.7;
  }

  &__body {
    background: white;
  }
}

@media screen and (max-width: 800px) {
  .popup {
    min-width: 200px;
    max-width: 100%;
    flex-grow: 20;
    display: flex;
    flex-direction: column;

    &__body {
      flex-grow: 20;
      overflow: hidden;
    }

    &__close-button {
      background: white;
      left: auto;
      right: 10px;
      top: 10px;
      z-index: 20;
      width: 40px;
      height: 40px;
    }

    &__close-button-icon {
      fill: black;
    }
  }
}

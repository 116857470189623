@import "assets/styles/_mixins.scss";

.charts-time-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__group {
    display: flex;
    flex-direction: column;

    .charts-time-nav_big & {
      flex-direction: row;
    }
  }

  &__button {
    @include reset-button-styles;
    border: 1px solid #E2E2E2;
    background: white;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
    height: 32px;
    width: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 32px;

    @include regular;
    font-size: 14px;
    color: black;
    margin-bottom: 10px;

    .charts-time-nav_big & {
      margin-bottom: 0;
      margin-right: 10px;
      order: 10;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover:not(:disabled) {
      background: #ededed;
    }

    &_long {
      .charts-time-nav_big & {
        order: 5;
        margin-right: 10px;
      }
      .charts-time-nav_big .charts-time-nav__group:last-child & {
        order: 15;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .2;
    }
  }

  &__icon-prev {
    fill: var(--blue-color);
    width: 7px;
    height: 10px;
    margin-right: 13px;
    &+& {
      margin-left: -14px;
      margin-right: 13px;
    }
  }

  &__icon-next {
    fill: var(--blue-color);
    width: 7px;
    height: 10px;
    margin-left: 13px;
    transform: scaleX(-1);
    &+& {
      margin-left: -1px;
    }
  }
}


@import 'assets/styles/_mixins.scss';

.territories-filter {
  &__item {
    @include regular;
    @include reset-button-styles;
    font-size: 14px;
    color: black;

    width: 100%;
    height: 50px;

    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    text-align: left;
    position: relative;

    &:hover
    {
        background-color: #F5F5F5;
    }
    
  }

  &__icon {
    margin-right: 20px;
  }

  &__count {
    white-space: nowrap;
  }
}
@import 'assets/styles/_mixins.scss';

.period-buttons {
  &__text-block {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 14px;
  }

  &__text-block-title {
    font-size: 14px;
    color: black;
    @include regular;
    margin-right: 10px;
    line-height: 18px;
  }

  &__extra-filters {

  }

  &__extra-filters-item {
    border: none;
    color: #4A90E2;
    line-height: 18px;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    background-color: unset;
    @include regular;

    &[aria-checked=true] {
      color: black;
    }

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  &__filter {
    display: flex;
    margin-top: 15px;
  }

  &__filter-item {
    @include reset-button-styles;
    font-size: 14px;
    flex-grow: 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 15px;
    cursor: pointer;

    .period-select__daytime & {
      flex-grow: 0;
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &:not(:last-child) {
      border-right: 0;
    }

    &[aria-checked=true] {
      background-color: #4A90E2;
      color: white;
    }
  }
}

@media screen and (max-width: 800px) {
  .period-buttons {
    &__filter-item {
      padding: 10px;
    }
  }
}

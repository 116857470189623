@import 'assets/styles/_mixins.scss';

.search {
  display: flex;
  align-items: center;

  height: 40px;
  width: 100%;

  padding-left: 15px;

  border: 1px solid #E2E2E2;

  &__input {
    background: white;
    border: none;
    -webkit-appearance: none;
    box-shadow: none;
    outline: none;
    margin: 0 15px 0 11px;
    width: 100%;

    @include regular;
    font-size: 14px;
    line-height: normal;
    color: #333333;
  }

  &__clear-button {
    @include reset-button-styles;
    outline: none;
    margin-right: 15px;

    &_hide {
      visibility: hidden;
    }
  }

  &__clear-icon {
    fill: #cccccc;
  }
}
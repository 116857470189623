@import 'assets/styles/_mixins.scss';

.header {
  background: var(--main-highlight-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;

  &__container {
    height: var(--header-height);
    display: flex;
    width: 100%;
    max-width: 100%;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
  }

  &__logo {
    flex-grow: 20;
  }

  &__logo-img {
    max-width: 100%;
    max-height: 50px;
  }

  &__nav-desktop {
    flex-grow: 20;
  }

  &__nav-mobile {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 20px;
  }

  &__tools-desktop {
    display: flex;
    flex-grow: 20;
    margin-right: auto;
    justify-content: flex-end;
  }

  &__tools-mobile {
    flex-direction: column;
  }

  &__open-mobile-menu-button {
    @include reset-button-styles;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__open-mobile-menu-icon {
    fill: white;
    width: 19px;
    height: 19px;
  }

  &__mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background: var(--main-highlight-color);
    padding: 20px;
    z-index: 100;
  }

  &__mobile-menu-close-button {
    @include reset-button-styles;
    position: absolute;
    height: 60px;
    width: 60px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__mobile-menu-close-icon {
    fill: white;
  }
}

@media screen and (max-width: 1100px) {
  .header {
    &__container {
      padding: 0 10px;
    }

    &__logo {
      max-width: 260px;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .header {
    &__container {
      padding: 0 10px;
    }

    &__logo {
      margin-right: 20px;
    }
  }
}

@import 'assets/styles/_mixins.scss';

.filters-container {
  background: var(--main-highlight-color);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
  border-radius: 3px 0 3px 3px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__save-button {
    @include reset-button-styles;
    margin: 20px 25px 20px 20px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    background: #4a90e2;
    border-radius: 3px;
    text-align: center;
    width: 100%;
    padding: 0 10px;

    @include regular;
    font-size: 14px;
    color: white;

    @include button-hover;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__reset-button {
    @include reset-button-styles;
    margin: 20px 20px 20px 5px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    background: none;
    border-radius: 3px;
    border: 1px solid rgba(41, 152, 233, 0.5);
    text-align: center;
    width: 100%;
    padding: 0 10px;

    @include regular;
    font-size: 14px;
    color: white;

    @include button-hover;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__toggle-button {
    @include reset-button-styles;
    position: absolute;
    left: 100%;
    top: 0;
    width: var(--control-button-size);
    height: var(--control-button-size);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--main-highlight-color);
    border-radius: 0 var(--control-button-radius) var(--control-button-radius) 0;

    &:hover {
      background: #2c3a5a;
    }
  }

  &__toggle-icon {
    fill: white;
    height: 13px;
    width: 10px;
  }

  &__mobile-toggle {
    @include reset-button-styles;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 20;
    box-shadow: 0 4px 10px rgba(66, 69, 76, 0.5);
    border-radius: var(--control-button-radius);
    width: var(--control-button-size);
    height: var(--control-button-size);
    background: var(--main-highlight-color);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: 0 4px 10px rgba(66, 69, 76, 0.5), inset 0 0 40px rgba(0, 0, 0, 0.5);
    }
  }

  &__mobile-toggle-icon {
    fill: white;
  }

  &__item {
    &_flex {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__item-button {
    @include reset-button-styles;
    display: flex;
    width: 100%;
    height: 54px;
    padding: 0 16px;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    position: relative;
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &:hover:not(:disabled) {
      background: #2c3a5a;
    }
    &:after {
      content: '';
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
    }
    .filters-container__item:last-child & {
      &:after {
        display: none;
      }
    }

    &_justify {
      justify-content: space-between;
    }
  }

  &__item-icon {
    fill: var(--blue-color);
    margin-right: 20px;
  }
  &__item-names {
    overflow: hidden;
  }
  &__item-name {
    @include regular;
    font-size: 9px;
    letter-spacing: 0.5px;
    color: rgba(214, 214, 255, 0.5);
    text-transform: uppercase;
  }
  &__item-desc {
    @include regular;
    font-size: 14px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__mobile-top {
    background: var(--blue-color);
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 16px;
  }

  &__mobile-title {
    @include regular;
    font-size: 18px;
    color: white;
  }

  &__mobile-close-button {
    @include reset-button-styles;
    display: flex;
    height: 70px;
    width: 50px;
    align-items: center;
    justify-content: center;
  }

  &__mobile-close-button-icon {
    fill: white;
    width: 20px;
    height: 20px;
  }

  &__tabs {
    display: flex;
    align-items: stretch;
    flex-shrink: 0;
  }

  &__tab {
    @include reset-button-styles;

    display: flex;
    flex-grow: 2;
    height: 45px;
    align-items: center;
    justify-content: center;
    background: #2b2b44;
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &:hover:not(:disabled) {
      background: #2c3a5a;
    }
    &_active {
      background: white;
      &:hover:not(:disabled) {
        background: white;
      }
    }
    &:disabled {
      cursor: not-allowed;
      &:hover {
        background: #2b2b44;
      }
    }
  }
  &__tab-icon {
    fill: white;
    margin-right: 6px;
    .filters-container__tab_active & {
      fill: black;
    }
    .filters-container__tab:disabled & {
      fill: #80808f;
    }
  }
  &__tab-name {
    @include regular;
    font-size: 12px;
    color: white;
    .filters-container__tab_active & {
      color: black;
    }
    .filters-container__tab:disabled & {
      color: #80808f;
    }
  }

  &__package-popup {
    flex-grow: 20;
    display: flex;
    flex-direction: column;
  }

  &__package-select {
    flex-grow: 20;
  }

  &__indicators {
    flex-grow: 20;
  }
}

@media screen and (max-width: 500px) {
  .filters-container {
    &__mobile-toggle {
      top: 10px;
      left: 10px;
    }
  }
}

@import 'assets/styles/_mixins.scss';

.export-control {
  width: var(--control-button-size);
  height: var(--control-button-size);

  &__button {
    padding: 0;
    border: none;
    width: var(--control-button-size);
    height: var(--control-button-size);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--control-button-radius);
    cursor: pointer;
    background: none;

    &:hover:not(:disabled) {
      box-shadow: inset 0 0 40px rgba(0,0,0,.5);
    }
    &:disabled {
      cursor: not-allowed;
    }

    &_active {
      background: var(--blue-color);
    }
  }
  &__button-icon {
    fill: white;

    .export-control__button:disabled & {
      opacity: .3;
    }
  }

  &__drop {
    background: white;
    filter: drop-shadow(0 4px 35px rgba(0, 0, 0, 0.4));
    width: 154px;
    position: absolute;
    right: calc(var(--control-button-size) + 20px);
    top: 0;
    z-index: 20;
    @include drop-transition;

    &:before {
      content: "";
      position: absolute;
      left: 100%;
      top: 10px;
      border-left: 10px solid white;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      margin-top: -5px;
    }
  }

  &__drop-title {
    @include regular;
    font-size: 9px;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    line-height: 16px;
    margin: 18px;
  }

  &__drop-list {

  }

  &__drop-option {
    @include reset-button-styles;
    display: block;
    height: 40px;
    width: 100%;
    position: relative;
    background: white;
    padding-left: 18px;
    text-align: left;
    border-bottom: 1px solid #E6E6E6;

    @include regular;
    font-size: 14px;
    line-height: 40px;
    color: black;
    text-transform: uppercase;
    text-decoration: none;

    &:first-child {
      border-top: 1px solid #E6E6E6;
    }

    &:hover {
      background: #D2E6F9;
    }
  }
}

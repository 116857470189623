@import 'assets/styles/_mixins.scss';

.input-range__slider {
  appearance: none;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  margin-left: -10px;
  margin-top: -13px;
  outline: none;
  position: absolute;
  top: 50%;
  background: #FFFFFF;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);

}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
}

.input-range__label {
  color: #aaaaaa;
  font-family: 'Helvetica Neue', san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
  display: none;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  @include regular;
  left: -50%;
  position: relative;
  font-size: 12px;
  color: black;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 6px;
  position: relative;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #3f51b5;
  background: linear-gradient(270deg, #0078AD 0.92%, #22A8C8 49.7%, #B4F1FE 100.12%);
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

/*# sourceMappingURL=index.css.map */

.app {
  max-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;

  @media screen and (max-width: 800px) {
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &_unlimited-height {
    height: auto;
    overflow: auto;
    position: relative;

    @media screen and (max-width: 800px) {
      height: auto;
      top: auto;
      bottom: auto;
      left: 0;
      right: 0;
    }
  }

  &__header {
    height: var(--header-height);
    position: relative;
    z-index: 110;

    .app_unlimited-height & {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
    }
  }
  &__map {
    position: absolute;
    top: var(--header-height);
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@import 'assets/styles/_mixins.scss';

.auth {
  &__back-to-auth {
    @include reset-button-styles;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-bottom: 25px;
  }

  &__back-to-auth-icon {

  }

  &__success-icon {
    margin-bottom: 18px;
    width: 48px;
    height: 48px;
  }

  &__success-title {
    @include regular;
    font-size: 24px;
    line-height: 1.25;
    color: #1E1E1E;
    margin-bottom: 20px;
  }

  &__success-text {
    @include regular;
    font-size: 14px;
    color: black;
    margin-bottom: 22px;
  }

  &__success-link {
    @include reset-button-styles;
    @include regular;
    font-size: 14px;
    color: #4A90E2;

    &:hover {
      text-decoration: underline;
    }
  }
}

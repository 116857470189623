@import 'assets/styles/_mixins.scss';

.map {
  &__zoom-tools {
  }
  &__get-new-polygons {
    position: absolute;
    right: 20px;
    bottom: 216px;
  }
  &__full-screen-toggle {
  }

  &__top-controls {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__export,
  &__layer-and-compare {
    margin: 20px 20px 0 0;
  }

  &__filter-container {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 376px;
    z-index: 40;
    max-height: calc(100vh - 40px - var(--header-height));
    //overflow: hidden;

    &_fixed {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: auto;
      height: auto;
      max-height: none;
    }
  }

  &__summary {
    position: absolute;
    bottom: 48px;
    right: 80px;
  }

  &__scale-info {
    position: absolute;
    bottom: 30px;
    right: 20px;
  }

  &__loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading-icon {
    width: 100px;
    height: 100px;
    fill: white;
  }

  &__territory-filter {
    position: absolute;
    bottom: 0;
    right: 290px;
    max-width: calc(100% - 290px - 120px);
  }

  &__yandex-map {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &__exit-selection-mode {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 40;
  }

  &__open-filters-panel-button {
    @include reset-button-styles;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 40;
    height: 40px;
    background: var(--main-highlight-color);
    display: flex;
    justify-content: center;
    align-items: center;

    @include regular;
    font-size: 14px;
    color: white;
    padding: 0 20px;
    border-radius: var(--control-button-radius);

    &:hover {
      background: #2c3a5a;
    }
  }

  &__open-filters-panel-icon {
    margin-left: 10px;
    fill: white;
    width: 10px;
    height: 13px;
    transform: scaleX(-1);
  }
}

.btn {
  @include reset-button-styles;
  width: var(--control-button-size);
  height: var(--control-button-size);
  background: darkblue;
}

@media screen and (max-width: 640px) {
  .map {
    &__territory-filter {
      right: 50px;
      width: 100%;
      max-width: calc(100% - 150px);
    }
  }
}

@media screen and (max-width: 500px) {
  .map {
    &__export {
      margin: 10px 10px 10px 0;
    }

    &__get-new-polygons {
      right: 10px;
      bottom: 206px;
    }

    &__summary {
      left: 10px;
      bottom: 80px;
      right: auto;
      max-width: 300px;
    }

    &__territory-filter {
      bottom: 40px;
      left: 10px;
      right: auto;
      max-width: calc(100% - 70px);
    }
  }
}

@media screen and (max-width: 380px) {
  .map {
    &__summary {
      max-width: 200px;
    }
  }
}

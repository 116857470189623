@import "assets/styles/_mixins.scss";

.header-nav {
  display: flex;
  flex-grow: 20;
  align-items: center;
  max-width: 400px;
  justify-content: space-around;

  &__item {
    @include regular;
    font-size: 14px;
    color: white;
    text-decoration: none;
    white-space: nowrap;
  }
}

@media screen and (max-width: 800px) {
  .header-nav {
    padding: 20px;
  }
}

@media screen and (max-width: 400px) {
  .header-nav {
    flex-direction: column;

    &__item {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}

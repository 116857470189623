@import 'assets/styles/_mixins.scss';

.month-range-select {
  &__container {
    position: relative;
  }

  &__select {
    display: flex;
    align-content: center;
    min-width: 400px;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #cccccc;
    border-radius: 4px;
    height: 38px;
    flex-grow: 20;
    width: 258px;
  }

  &__value-button {
    @include reset-button-styles;
    @include regular;
    font-size: 14px;
    line-height: 38px;
    color: #828282;
    text-align: left;
    height: 36px;
    padding: 0 40px 0 13px;
    border: none;
    border-right: 1px solid #cccccc;
    position: relative;
    flex-grow: 20;
    &:hover {
      box-shadow: inset 0 0 300px rgba(0, 0, 0, 0.05);
    }
  }

  &__value {
  }

  &__value-part {
    @include regular;
    font-size: 14px;
    color: black;
    &_hover {
      opacity: 0.5;
    }
  }

  &__date-icon {
    position: absolute;
    width: 16px;
    height: 18px;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__reset-button {
    @include reset-button-styles;
    min-width: 36px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.05);
    }
  }

  &__clear-icon {
    fill: #b3b3b3;
    width: 10px;
    height: 10px;
  }

  &__apply {
    @include reset-button-styles;
    height: 38px;
    font-size: 14px;
    color: white;
    background: var(--blue-color);
    border-radius: 3px;
    cursor: pointer;
    padding: 0 38px;
    margin-left: 18px;

    &:disabled {
      background: #bdbdbd;
      cursor: not-allowed;
    }

    @include button-hover;

    &_mobile {
      width: 100%;
    }
  }

  &__selector {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    background: white;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.3);
    padding: 20px 0;
  }

  &__year {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    padding: 0 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__year-value {
    width: 62px;
    min-width: 62px;
    @include regular;
    font-size: 14px;
    line-height: 32px;
    color: #828282;
  }

  &__months {
    display: flex;
    flex-wrap: wrap;

    .month-range-select__year:last-child & {
      margin-bottom: -15px;
    }
  }

  &__month {
    @include reset-button-styles;
    width: calc(100% / 4);
    @include regular;
    height: 32px;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    color: black;
    border-radius: 3px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    position: relative;

    // Используется для бордера
    &:after {
      content: '';
      position: absolute;
      left: -1px;
      right: -1px;
      bottom: -1px;
      top: -1px;
      z-index: 10;
      border: 1px solid rgba(0, 0, 0, 0.5);
      display: none;
      z-index: 70;
      border-radius: inherit;
    }

    &:disabled {
      color: #bdbdbd;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      color: white;
      background: rgba(28, 131, 226, 0.6);
    }

    &_selected-first {
      background: rgba(28, 131, 226, 1);
      color: white;
      &:not(:disabled):hover {
        border: 1px solid rgba(0, 0, 0, 0.5);
        background: rgba(28, 131, 226, 1);
        color: white;
      }
    }

    &_boundary {
      color: white;
      &:not(:disabled):hover:after {
        display: block;
      }
    }

    &_inner {
      color: white;
    }

    &_hover-range {
      color: white;
    }
  }

  &__month-text {
    position: relative;
    z-index: 60;
  }

  &__month-underlay {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    top: -1px;
    z-index: 10;
    background: rgba(28, 131, 226, 0.6);

    .month-range-select__month:nth-child(4n) & {
      border-radius: 0 3px 3px 0;
    }
    .month-range-select__month:nth-child(4n + 1) & {
      border-radius: 3px 0 0 3px;
    }

    &_start {
      left: 4px;
    }

    &_end {
      right: 4px;
    }

    .month-range-select__month:disabled & {
      display: none;
    }
  }

  &__month-background {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    top: -1px;
    z-index: 20;
    background: rgba(28, 131, 226, 1);
    border-radius: 4px;
  }
}

@media screen and (max-width: 800px) {
  .month-range-select {
    &__container {
      width: 100%;
    }

    &__apply {
      margin-left: 0;
    }

    &__selector {
      position: static;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 500px) {
  .month-range-select {
    &__select {
      min-width: 200px;
    }

    &__input-wrapper {
      width: auto;
    }

    &__value-part {
      font-size: 13px;
    }

    &__year {
      flex-direction: column;
      padding: 0;
    }

    &__year-value {
      width: 62px;
      margin: 0 auto 10px;
      text-align: center;
    }

    &__month {
      font-size: 13px;
    }
  }
}

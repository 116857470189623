@import 'assets/styles/_mixins.scss';

/**
  * Charts fill screen are here because the application loads that component asynchronously
  * and there ois some problem with styles (I don't remember it at the moment of writing this text).
  * And so, loading it here, solves the problem.
  */
.charts-fs {
  position: fixed;
  // top: calc(var(--header-height) + 14px);
  top: 84px;
  left: 14px;
  bottom: 14px;
  right: 14px;
  height: calc(100% - 84px - 14px);
  max-height: calc(100% - 84px - 14px);
  background: white;
  z-index: 100;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    min-height: 54px;
    background: #f5f5f5;
    padding: 0 21px 0 30px;
  }

  &__indicator {
    display: flex;
    align-items: center;
  }

  &__indicator-label {
    @include regular;
    font-size: 14px;
    color: black;
    margin-right: 30px;
  }

  &__indicator-select {
    min-width: 300px;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__settings-bar {
    padding: 20px 30px 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__settings-group {
    margin-left: 24px;
  }

  &__toggle-filters {
    @include reset-button-styles;
    border: 1px solid #e2e2e2;
    border-left: none;
    background: white;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
    height: 36px;
    padding: 0 13px 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36px;
    margin-right: 12px;

    @include regular;
    font-size: 14px;
    color: black;

    &_opened {
      padding: 0 14px;
    }

    &:hover {
      background: #ededed;
    }
  }

  &__toggle-filters-icon {
    fill: #4a90e2;
    width: 7px;
    height: 10px;
    margin-left: 13px;
    transform: scaleX(-1);

    .charts-fs__toggle-filters_opened & {
      transform: scaleX(1);
      margin-left: 0;
    }
  }

  &__settings-zoom-select {
    min-width: 300px;
  }

  &__settings-interval-select {
    min-width: 300px;
  }

  &__content {
    flex-grow: 100;
    display: flex;
    align-items: stretch;
    width: 100%;
    overflow: hidden;
    max-width: 100%;
  }

  &__measures-container {
    width: 387px;
    min-width: 387px;
    border-right: 1px solid #e2e2e2;
    display: none;
    flex-direction: column;
    justify-content: space-between;

    &_visible {
      display: flex;
    }
  }

  &__measures-scroll-container {
    padding: 24px 30px 94px;
    overflow-y: auto;
  }

  &__build-chart {
    background: #f5f5f5;
    height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 30px;
  }

  &__build-chart-button {
    @include reset-button-styles;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    background: #4a90e2;
    border-radius: 3px;
    text-align: center;
    padding: 0 46px;

    @include regular;
    font-size: 14px;
    color: white;

    @include button-hover;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__main-part {
    flex-grow: 20;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
  }

  &__loading {
    flex-grow: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading-icon {
    width: 100px;
    height: 100px;
    fill: #4a90e2;
  }

  &__chart-itself {
    flex-grow: 100;
  }

  &__ratings-itself {
    flex-grow: 100;
    padding: 20px;
    overflow-y: auto;
  }

  &__time-nav {
    padding: 0 30px 30px 80px;
  }

  &__settings-indicator {
    display: flex;
    align-items: center;
    margin-left: auto;

    & + & {
      margin-left: 16px;
    }
  }

  &__settings-checkbox {
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.indicators {
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  &__scroll {
    flex-grow: 10;
    margin: 16px 10px 16px 16px;
    padding-right: 10px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f2f2f2;
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #828282;
      border-radius: 3px;
    }
  }

  &__error {
    @include regular;
    font-size: 14px;
    color: black;
    margin: 14px 0;
    text-align: center;
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e4e4;
  }

  &__form {
    min-height: 20px;
  }
  &__input-holder {
    padding: 20px 0 25px;
    border-bottom: 1px solid #e4e4e4;
    &:last-child {
      border-bottom: none;
    }
  }
  &__input-name {
    @include regular;
    font-size: 14px;
    color: black;
    margin-bottom: 14px;
  }
  &__checkboxes {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__checkbox-item {
    width: calc(50% - 8px);
    margin-right: 8px;
    margin-bottom: 14px;
    display: flex;
    align-items: flex-start;
  }
  &__checkbox {
    display: flex;
    min-width: 20px;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0;
    border-radius: 2px;
    margin-right: 10px;
    &:after {
      content: '';
      width: 13px;
      height: 11px;
      background: url(../images/icon-checkbox-check.svg) no-repeat center;
      background-size: 13px 11px;
      pointer-events: none;
    }
    &[aria-checked='true'] {
      background: var(--blue-color);
      border-color: var(--blue-color);
    }
  }
  &__checkbox-name {
    @include regular;
    font-size: 14px;
    color: black;
  }

  &__radio {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &-item {
      line-height: 20px;
      display: flex;
      cursor: pointer;
      width: calc(50% - 8px);
      margin-right: 8px;
      margin-bottom: 14px;
      position: relative;
    }

    &-icon {
      position: absolute;
      border-radius: 100%;
      stroke: #cccccc;
    }

    &-input {
      visibility: hidden;
      height: 20px;
      width: 20px;
      margin: 0;
      padding: 0;
    }

    &-input:checked + &-icon {
      stroke: #4a90e2;
      stroke-width: 10;
    }

    &-name {
      margin-left: 10px;
      @include regular;
      font-size: 14px;
      color: black;
      width: calc(100% - 30px);
      line-height: 18px;
    }
  }

  &__range {
    margin: 30px 10px 20px;
  }

  &__object {
    padding: 15px 0 11px;
    border-bottom: 1px solid #e4e4e4;
    &:last-child {
      border-bottom: none;
    }
  }
  &__object-header {
    display: flex;
    align-items: flex-start;
    &:only-child {
      margin-bottom: 0;
    }
  }
  &__object-checkbox {
    margin-right: 18px;
  }

  &__object-name {
    @include regular;
    font-size: 14px;
    color: black;
  }
  &__object-color {
    display: inline-block;
    width: 20px;
    height: 10px;
    border-radius: 5px;
    margin-left: 6px;
  }
  &__object-desc {
    @include regular;
    font-size: 11px;
    color: #808080;
  }
  &__object-range {
    margin: 30px 10px;
    height: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__object-range-name {
    @include regular;
    font-size: 11px;
    color: #808080;
    margin-top: 12px;
  }

  &__build-chart-button {
    @include reset-button-styles;
    height: 36px;
    line-height: 36px;
    background: #4a90e2;
    border-radius: 3px;
    text-align: center;
    padding: 0 46px;

    @include regular;
    font-size: 14px;
    color: white;

    @include button-hover;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__chart-tab-config {
    display: none;

    &_visible {
      display: block;
    }
  }

  &__charts-interval-select {
    padding-top: 20px;
  }

  &__charts-zoom-select {
    padding-top: 20px;
  }

  &__chart-time-nav {
    margin-top: 10px;
  }

  &__chart-loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__chart-loading-icon {
    width: 40px;
    height: 40px;
    fill: var(--blue-color);
  }

  &__period-select-trigger {
    border-bottom: 1px solid #e4e4e4;
  }

  &__chart-nav {
    margin-bottom: 10px;
  }
}

@import 'assets/styles/_mixins.scss';

.auth {
  &__input-holder {
    margin-bottom: 20px;
  }

  &__error-text {
    max-width: 300px;
    @include regular;
    font-size: 14px;
    line-height: 20px;
    color: black;
    margin-top: 4px;

    &_red {
      color: #EF193F;
    }
  }
}

@import 'assets/styles/_mixins.scss';

.city-selector {
  border: 1px solid rgba(41, 152, 233, 0.5);
  width: 185px;
  height: 35px;
  border-radius: 17px;
  position: relative;
  z-index: 20;
  padding: 0;
  transition: all 0.2s ease-in-out;

  &_active {
    border-color: #2998e9;
  }

  &__current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    background: none;
    border: none;
    width: 100%;
    cursor: pointer;
    margin: 0;
  }

  &__current-value {
    line-height: 33px;
    font-size: 12px;
    color: #2998e9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__caret-icon {
    transition: all 0.2s ease-in-out;
    .city-selector_active & {
      transform: rotate(-180deg);
    }
  }

  &__loading {
    width: 100%;
    height: 100%;
    flex-grow: 20;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading-icon {
    fill: white;
    width: 20px;
    height: 20px;
  }

  &__drop {
    background: white;
    filter: drop-shadow(0 4px 35px rgba(0, 0, 0, 0.4));
    width: 200px;
    position: absolute;
    left: 50%;
    top: 56px;
    margin-left: -100px;
    z-index: 20;
    @include drop-transition;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: -10px;
      border-bottom: 10px solid white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      margin-left: -10px;
    }
  }
  &__country {
  }
  &__country-button {
    display: flex;
    width: 100%;
    padding: 0 19px;
    align-items: center;
    justify-content: space-between;
    height: 37px;
    font-size: 14px;
    color: black;
    background: white;
    border: none;
    cursor: pointer;
    position: relative;
  }
  &__country-icon {
    display: block;
    width: 10px;
    height: 10px;
    position: relative;
    transition: transform 0.2s ease-in;
    &:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 10px;
      background: rgba(30, 30, 30, 0.8);
      left: 0;
      top: 4px;
    }
    &:after {
      content: '';
      position: absolute;
      height: 10px;
      width: 2px;
      background: rgba(30, 30, 30, 0.8);
      left: 4px;
      top: 0;
      opacity: 0;
      transition: transform 0.2s ease-in;
    }
    &_opened {
      transform: rotate(180deg);
      &:after {
        opacity: 1;
      }
    }
  }
  &__cities {
    display: none;
    &_opened {
      display: block;
    }
  }
  &__city {
    width: 100%;
    display: flex;
    min-height: 37px;
    align-items: center;
    text-align: left;
    padding-left: 47px;
    padding: 6px 4px 5px 47px;

    font-size: 14px;
    color: black;
    background: white;
    border: none;
    cursor: pointer;
    position: relative;

    &_selected {
      background: #d2e6f9;
    }
  }
  &__drop-item-icon {
    fill: #4a90e2;
    position: absolute;
    left: 17px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

@media screen and (max-width: 1100px) {
  .city-selector {
    width: 150px;
  }
}

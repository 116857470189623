@import "assets/styles/_mixins.scss";

.layers-control {
  width: var(--control-button-size);
  height: var(--control-button-size);
  position: relative;


  &__button {
    padding: 0;
    border: none;
    width: var(--control-button-size);
    height: var(--control-button-size);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--control-button-radius);
    cursor: pointer;
    background: none;
    &:hover:not(:disabled) {
      box-shadow: inset 0 0 40px rgba(0,0,0,.5);
    }
    &:disabled {
      cursor: not-allowed;
    }

    &_active {
      background: var(--blue-color);
    }
  }
  &__button-icon {
    fill: white;

    .layers-control__button:disabled & {
      opacity: .3;
    }
  }

  &__drop {
    background: white;
    filter: drop-shadow(0 4px 35px rgba(0, 0, 0, 0.4));
    width: 184px;
    position: absolute;
    right: calc(var(--control-button-size) + 20px);
    top: 0;
    z-index: 20;
    @include drop-transition;

    &:before {
      content: "";
      position: absolute;
      left: 100%;
      top: 10px;
      border-left: 10px solid white;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      margin-top: -5px;
    }
  }

  &__drop-title {
    @include regular;
    font-size: 9px;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    margin: 18px;
  }

  &__drop-list {

  }

  &__drop-option {
    @include reset-button-styles;
    display: flex;
    align-items: center;
    height: 37px;
    width: 100%;
    position: relative;
    background: white;
    padding-left: 48px;
    text-align: left;

    @include regular;
    font-size: 14px;
    line-height: 37px;
    color: black;

    &:hover:not(:disabled) {
      background: #D2E6F9;
    }

    &:disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  &__drop-option-icon {
    fill: blue;
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);

    .layers-control__drop-option:disabled & {
      fill: black;
      fill-opacity: 0.3;
    }
  }

  &__drop-option-info {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
  }

  &__drop-option-info-icon {
    display: block;
    width: 16px;
    height: 16px;
  }

  &__drop-option-info-tooltip {
    position: absolute;
    z-index: 100;
    width: 210px;
    min-height: 99px;
    left: 50%;
    top: calc(100% + 20px);
    background: rgba(25, 48, 82, 0.81);
    padding: 14px;

    @include regular;
    font-size: 14px;
    line-height: 1.57;
    color: white;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;

    .layers-control__drop-option-info-icon:hover + & {
      opacity: 1;
      visibility: visible;
    }

    &:after {
      content: "";
      border-bottom: 10px solid rgba(25, 48, 82, 0.81);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      left: 50%;
      top: -10px;
      margin-left: -10px;
    }
  }
}

@import 'assets/styles/_mixins.scss';

.reports {
  padding: 124px 30px 30px;
  max-width: 844px;
  margin-left: auto;
  margin-right: auto;

  &__title {
    @include regular;
    font-size: 50px;
    color: black;
    margin: 0 0 40px;
  }

  &__search {
    margin-bottom: 60px;
  }

  &__search-title {
    @include regular;
    font-size: 16px;
    color: #7a7a7a;
    margin-bottom: 10px;
  }

  &__search-row {
    display: flex;
    width: 100%;
  }

  &__search-input-holder {
    flex-grow: 20;
    position: relative;
    margin-right: 20px;
  }

  &__search-input {
    background: white;
    border: 1px solid #bdbdbd;
    border-radius: 3px;
    padding: 0 14px;
    width: 100%;
    height: 38px;
    -webkit-appearance: none;
    box-shadow: none;

    @include regular;
    font-size: 14px;
    line-height: normal;
    color: #333333;
  }

  &__search-clear-button {
    @include reset-button-styles;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    .reports__search-input-holder:focus-within & {
      display: flex;
    }

    &:hover {
      display: flex;
    }
  }

  &__search-clear-icon {
    fill: #cccccc;
  }

  &__search-button {
    @include reset-button-styles;
    height: 36px;
    line-height: 36px;
    background: #4a90e2;
    border-radius: 3px;
    text-align: center;
    padding: 0 46px;

    @include regular;
    font-size: 14px;
    color: white;
    min-width: 142px;
    width: 142px;

    @include button-hover;
  }

  &__list {
  }

  &__item {
    display: block;
    position: relative;
    margin-bottom: 40px;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-icon {
    fill: var(--blue-color);
    position: absolute;
    left: -32px;
    top: -4px;
  }

  &__item-content {
  }

  &__item-file-row {
    display: flex;
    margin-bottom: 10px;
  }

  &__item-file-name {
    @include regular;
    font-size: 16px;
    color: var(--blue-color);
    margin-right: 10px;
  }

  &__item-file-info {
    @include regular;
    font-size: 16px;
    color: #828282;
  }

  &__item-desc {
    @include regular;
    font-size: 14px;
    line-height: 1.43;
    color: black;
  }

  &__loading {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading-icon {
    fill: var(--blue-color);
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 900px) {
  .reports {
    padding-top: 104px;

    &__title {
      font-size: 24px;
    }

    &__item {
      padding-left: 30px;
    }

    &__item-icon {
      fill: var(--blue-color);
      position: absolute;
      left: 0;
    }

    &__item-content {
    }

    &__item-file-row {
      flex-direction: column;
      margin-bottom: 10px;
    }

    &__item-file-name {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &__item-file-info {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 500px) {
  .reports {
    &__search-row {
      flex-direction: column;
      align-items: flex-start;
    }

    &__search-input-holder {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

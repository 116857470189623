@import "assets/styles/_mixins.scss";

.opacity-control {
  width: var(--control-button-size);
  height: var(--control-button-size);
  position: relative;

  &__button {
    padding: 0;
    border: none;
    width: var(--control-button-size);
    height: var(--control-button-size);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--control-button-radius);
    cursor: pointer;
    background: none;
    &:hover {
      box-shadow: inset 0 0 40px rgba(0,0,0,.5);
    }

    &_active {
      background: var(--blue-color);
    }
  }
  &__button-icon {
    fill: white;
  }

  &__current-value {
    @include bold;
    position: absolute;
    bottom: 3px;
    right: 3px;
    background: #232438;
    color: white;
    border-radius: 20px;
    width: 18px;
    height: 14px;
    font-size: 7px;
    line-height: 14px;
    box-shadow: 2px 2px 4px rgba(0,0,0, .3);
  }

  &__drop {
    background: white;
    filter: drop-shadow(0 4px 35px rgba(0, 0, 0, 0.4));
    width: 244px;
    position: absolute;
    right: calc(var(--control-button-size) + 20px);
    top: 0;
    z-index: 20;
    @include drop-transition;

    &:before {
      content: "";
      position: absolute;
      left: 100%;
      top: 10px;
      border-left: 10px solid white;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      margin-top: -5px;
    }
  }

  &__drop-title {
    @include regular;
    font-size: 9px;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    margin: 18px 18px 40px;
  }

  &__drop-range {
    margin: 0 24px 18px;
  }

  &__range-track {
    background: linear-gradient(270deg, #0078AD 0.92%, #22A8C8 49.7%, #B4F1FE 100.12%) !important;

    &_active {
      background: none !important;
    }
  }

  &__range-value-label {
    top: -35px !important;
    @include regular;
    font-size: 12px !important;
    color: black !important;

  }
}

@import "assets/styles/_mixins.scss";

.toggle-control {
  border: none;
  padding: 0;
  background: #CCCCCC;
  border-radius: 10px;
  height: 20px;
  width: 34px;
  position: relative;
  cursor: pointer;
  &[aria-checked=true] {
    background: rgba(41, 152, 233, 0.8);
    &:before {
      left: 16px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    height: 16px;
    width: 16px;
    background: white;
    border-radius: 50%;
    transition: left .15s ease-in-out;
  }
}

.map-selection {
  position: absolute;
  border: 2px dotted var(--blue-color);
  pointer-events: none;

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: s-resize;
    pointer-events: none;
    z-index: 1000;
  }
}

@import 'assets/styles/_mixins.scss';

.static-page {
  padding: 124px 30px 30px;
  max-width: 844px;
  margin-left: auto;
  margin-right: auto;

  &__image {
    margin-bottom: 50px;
    margin-top: 20px;
    position: relative;
    width: 100%;
    padding-bottom: 60%;
  }

  &__img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__content {
    @include regular;
    font-size: 14px;
    line-height: 1.57;
    color: #1E1E1E;

    p {
      margin: 20px 0;
    }

    h1 {
      color: black;
      font-size: 50px;
      margin: 0 0 20px;
    }

    h2 {
      font-size: 36px;
      margin: 0 0 20px;
    }

    h3 {
      font-size: 24px;
      margin: 0 0 10px;
    }

    h4 {
      font-size: 20px;
      margin: 0 0 10px;
    }

    h5 {
      font-size: 18px;
      margin: 0 0 10px;
    }

    ul {
      list-style: none;
      margin: 20px 0;

      li {
        position: relative;
        padding-left: 16px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 9px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: var(--blue-color);
        }
      }
    }

    ol {
      list-style: decimal outside;
      margin: 20px 0 20px 16px;

      li {
        margin: 2px 0;
      }
    }

    a {
      color: var(--blue-color);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    b, strong {
      @include bold;
    }

    i, em {
      font-style: italic;
    }

    img {
      max-width: 100%;
    }

    table {
      border-collapse: collapse;
      margin: 20px 0;

      th {
        border-bottom: 1px solid #E2E2E2;
        padding: 10px 8px;
        @include bold;
        text-align: left;
      }

      td {
        border-bottom: 1px solid #E2E2E2;
        padding: 10px 8px;
      }
    }
  }

  &__loading {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading-icon {
    fill: var(--blue-color);
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 800px) {
  .static-page {
    padding: 124px 20px 20px;

    &__content {
      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 26px;
      }

      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 18px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }
}

@import 'assets/styles/_mixins.scss';

.favorites {
  padding: 36px 40px 50px 50px;

  &__title {
    font-size: 24px;
    color: #1e1e1e;
    margin-bottom: 20px;
    @include regular;
  }
}

.favorites-container {
  width: 100%;
  max-height: 460px;
  padding-right: 10px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #828282;
    border-radius: 3px;
  }

  &__item {
    @include reset-link-styles;
    width: 100%;
    height: 50px;
    display: flex;
    background: #ebebeb;
    align-items: center;
    position: relative;
    padding: 0 20px;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    margin-right: 50px;

    &-button {
      @include reset-button-styles;
      padding: 5px;
      margin-left: auto;
      visibility: hidden;
    }

    &-drop {
      position: fixed;
      z-index: 20;
      background: white;
      filter: drop-shadow(0 4px 35px rgba(0, 0, 0, 0.4));
      @include drop-transition;

      &-button {
        @include reset-button-styles;
        width: 100%;
        display: block;
        font-size: 14px;
        padding: 10px 15px;
        text-align: left;

        &:hover {
          background: #d2e6f9;

          &:first-child {
            &:before {
              border-right: 10px solid #d2e6f9;
            }
          }
        }

        &:first-child {
          &:before {
            content: '';
            position: absolute;
            left: -15px;
            top: 7px;
            border-bottom: 10px solid transparent;
            border-left: 10px solid transparent;
            border-right: 10px solid white;
            border-top: 10px solid transparent;
            margin-left: -5px;
          }
        }
      }
    }
  }

  &__item:hover &__item-button {
    visibility: visible;
  }

  &__item:hover {
    background: #cbe4fb;
  }
}

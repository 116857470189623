@import 'assets/styles/_mixins.scss';

.selected-polygons {
  position: absolute;
  background: #242438;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  top: 20px;
  right: 82px;
  padding: 12px 12px 12px 20px;
  display: flex;
  align-items: center;

  &__text {
    @include regular;
    font-size: 14px;
    color: white;
    margin-right: 12px;
  }

  &__confirm-button {
    @include reset-button-styles;
    width: 138px;
    height: 40px;
    background: var(--blue-color);
    border-radius: 3px;
    margin-right: 12px;
    text-align: center;

    @include regular;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: white;

    @include button-hover;
  }

  &__deny-button {
    @include reset-button-styles;
    width: 138px;
    height: 40px;
    background: #31314D;
    border-radius: 3px;
    text-align: center;

    @include regular;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: white;

    &:disabled {
      background: lighten(#31314D, 20%);
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background: lighten(#31314D, 10%);
    }
  }
}

@media screen and (max-width: 800px) {
  .selected-polygons {
    top: auto;
    right: auto;
    bottom: 60px;
    left: 10px;
    padding: 10px;
    flex-wrap: wrap;
    max-width: calc(100% - 10px - 62px);

    &__text {
      margin: 0 0 10px;
      width: 100%;
    }

    &__confirm-button {
      width: 100px;
      height: 30px;
      font-size: 13px;
      line-height: 30px;
    }

    &__deny-button {
      width: 100px;
      height: 30px;
      font-size: 13px;
      line-height: 30px;
    }
  }
}

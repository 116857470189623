@import 'assets/styles/_mixins.scss';

.header-tools {
  display: flex;
  align-items: center;

  &__lang-selector {
    margin-right: 15px;

    .header__tools-mobile & {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 15px;
    }
  }

  &__city-selector {
    margin-right: 15px;

    .header__tools-mobile & {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 15px;
    }
  }

  &__button {
    @include reset-button-styles;
    width: 35px;
    height: 35px;
    border: 1px solid rgba(41, 152, 233, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  &__button-icon {
    width: 14px;
    height: 14px;
    fill: #4A90E2;
  }

  &__user {
    position: relative;
    display: flex;
  }

  &__user-drop {
    background: white;
    filter: drop-shadow(0 4px 35px rgba(0, 0, 0, 0.4));
    width: 100px;
    position: absolute;
    right: 45px;
    top: 56px;
    z-index: 20;
    @include drop-transition;

    &:before {
      content: "";
      position: absolute;
      right: 17px;
      top: -10px;
      border-bottom: 10px solid white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      margin-left: -5px;
    }
  }

  &__user-drop-item {
    width: 100%;
    height: 37px;
    align-items: center;
    text-align: center;

    font-size: 14px;
    color: black;
    background: white;
    border: none;
    cursor: pointer;
    position: relative;
  }
}

@media screen and (max-width: 800px) {
  .header-tools {
    &__user-drop {
      left: 50%;
      right: auto;
      margin-left: -50px;

      &:before {
        right: auto;
        left: 50%;
        margin-left: -10px;
      }
    }
  }
}

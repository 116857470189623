@mixin reset-button-styles {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
}

@mixin reset-link-styles {
  color: inherit;
  text-decoration: inherit;
}

@mixin regular() {
  font-family: 'PT Sans', sans-serif;
  font-weight: 400;
}
@mixin bold() {
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
}

@mixin drop-transition() {
  transition: all .2s cubic-bezier(.38,1.75,.82,1.26);

  &-enter {
    opacity: 0;
    transform: scale(.9) translateY(20px);
  }
  &-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
  &-exit {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
  &-exit-active {
    opacity: 0;
    transform: scale(.9) translateY(20px);
  }
}

@mixin button-hover() {
  &:hover:not(:disabled) {
    background: darken(#4a90e2, 15%);
  }
}

@import 'assets/styles/_mixins.scss';

.auth {
  &__caption {
    @include regular;
    color: #1E1E1E;
    margin-bottom: 20px;
    font-size: 24px;
  }

  &__description {
    @include regular;
    font-size: 14px;
    margin-bottom: 20px;
  }
}

@import "assets/styles/_mixins.scss";

.charts {
  $root: &;

  &__nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
  }

  &__nav-bar-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav-bar-button {
    @include reset-button-styles;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 20px;
    margin-right: 10px;

    &:only-child {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__back-icon {
    fill: var(--blue-color);
    width: 18px;
    height: 12px;
  }

  &__export-icon {
    fill: var(--blue-color);
    width: 22px;
    height: 16px;
  }

  &__full-screen-icon {
    fill: var(--blue-color);
    width: 18px;
    height: 15px;
  }

  &__title {
    @include regular;
    font-size: 18px;
    line-height: 1.44;
    color: black;

    text-align: center;
  }

  &__chart-itself {
    min-height: 300px;
  }

  &__chart-no-data {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    font-size: 14px;
    line-height: 1.4;
    color: black;
  }

  &__tooltip {
    padding: 8px;
    font-size: 12px;

    &_is-full-screen {
      padding: 10px;
      font-size: 17px;

      #{$root}__tooltip-content {
        margin-top: 6px;
      }
    }
  }

  &__tooltip-content {
    font-weight: 700;
    margin-top: 3px;
  }

  .google-visualization-tooltip {
    pointer-events: none; // избегаем взаимодействия с тултипом
  }
}

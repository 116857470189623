@import 'assets/styles/_mixins.scss';

.current-area-summary {
  background: #ffffff;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;

  &__map-name {
    @include bold;
    font-size: 14px;
    text-align: center;
    color: black;
  }

  &__caption {
    @include regular;
    font-size: 14px;
    text-align: center;
    color: black;
    margin-right: 4px;
  }

  &__value {
    @include bold;
    font-size: 14px;
    text-align: center;
    color: black;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .current-area-summary {
    height: auto;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (max-width: 380px) {
  .current-area-summary {
    &__caption {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__value {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.hint-pointer {
  position: absolute;
  right: -252px;
  width: 246px;
  top: 0;
  height: 100%;
  display: flex;

  &__caption {
    margin: auto;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    color: #FFFFFF;
    z-index: 1;
  }
  &__icon {
    position: absolute;
  }
}

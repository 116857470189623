@import 'assets/styles/_mixins.scss';

.favorites-saver {
  padding: 36px 40px 50px 50px;

  &__title {
    font-size: 24px;
    color: #1e1e1e;
    margin-bottom: 25px;
    @include regular;
  }

  &__control {
    label {
      @include regular();
      font-size: 14px;
      margin-bottom: 8px;
      display: inline-block;
    }

    input {
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 3px;
      padding: 0 10px;
      width: 100%;
      @include regular();
      line-height: normal;
      font-size: 14px;
      height: 36px;
    }
  }

  &__button {
    @include reset-button-styles;
    margin-top: 20px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    background: #4a90e2;
    border-radius: 3px;
    border: 1px solid rgba(41, 152, 233, 0.5);
    text-align: center;
    padding: 0 46px;

    @include regular;
    font-size: 14px;
    color: white;

    @include button-hover;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__input-wrapper {
    position: relative;
  }

  &__error-icon {
    position: absolute;
    right: 10px;
    bottom: calc(50% - 18px / 2);
  }
}

@import 'assets/styles/_mixins.scss';

.map-territory-filter {
  --pallete-default-colors-1: #e6e6ef;
  --pallete-default-colors-2: #9f9fc1;
  --pallete-default-colors-3: #5b5b8f;

  --pallete-default-b-colors-1: #E8C295;
  --pallete-default-b-colors-2: #F8A94C;
  --pallete-default-b-colors-3: #D97908;

  --pallete-temperature-colors-1: #016711;
  --pallete-temperature-colors-2: #C3BE03;
  --pallete-temperature-colors-3: #B00303;

  --pallete-red-colors-1: #FF9898;
  --pallete-red-colors-2: #FF5555;
  --pallete-red-colors-3: #E71D1D;
  --pallete-red-colors-4: #A30000;
  --pallete-red-colors-5: #530000;

  --pallete-yellow-red-colors-1: #FFE27C;
  --pallete-yellow-red-colors-2: #F7B86C;
  --pallete-yellow-red-colors-3: #EE804F;
  --pallete-yellow-red-colors-4: #E44631;
  --pallete-yellow-red-colors-5: #BE1313;

  height: 28px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px 3px 0 0;

  &__name {
    @include regular;
    font-size: 14px;
    color: black;
    margin-right: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 20;
  }

  &__range-container {
    display: flex;
    align-items: center;
    flex-grow: 20;
  }

  &__range {
    flex-grow: 1;
    width: 244px;
    min-width: 244px;
  }

  &__label {
    @include regular;
    font-size: 14px;
    color: black;
    margin-right: 14px;

    &:last-child {
      margin-left: 14px;
      margin-right: 0;
    }
  }

  &__range-track {
    &_a {
      background: linear-gradient(
        270deg,
        var(--pallete-default-colors-3) 0.92%,
        var(--pallete-default-colors-2) 49.7%,
        var(--pallete-default-colors-1) 100.12%
      ) !important;
    }
    &_a-mono {
      background: var(--pallete-default-colors-3) !important;
    }
    &_b {
      background: linear-gradient(
        270deg,
        var(--pallete-default-b-colors-3) 1.15%,
        var(--pallete-default-b-colors-2) 43.7%,
        var(--pallete-default-b-colors-1) 87.68%
      ) !important;
    }
    &_b-mono {
      background: var(--pallete-default-b-colors-3) !important;
    }
    &_a-temperature, &_b-temperature {
      background: linear-gradient(
        270deg,
        var(--pallete-temperature-colors-3) 0.92%,
        var(--pallete-temperature-colors-2) 49.7%,
        var(--pallete-temperature-colors-1) 100.12%
      ) !important;
    }
    &_a-temperature-reverse, &_b-temperature-reverse {
      background: linear-gradient(
        270deg,
        var(--pallete-temperature-colors-1) 0.92%,
        var(--pallete-temperature-colors-2) 49.7%,
        var(--pallete-temperature-colors-3) 100.12%
      ) !important;
    }
    &_a-red, &_b-red {
      background: linear-gradient(
        270deg,
        var(--pallete-red-colors-5) 0.92%,
        var(--pallete-red-colors-4) 25%,
        var(--pallete-red-colors-3) 49.7%,
        var(--pallete-red-colors-2) 75%,
        var(--pallete-red-colors-1) 100.12%
      ) !important;
    }
    &_a-yellow-red, &_b-yellow-red {
      background: linear-gradient(
        270deg,
        var(--pallete-yellow-red-colors-5) 0.92%,
        var(--pallete-yellow-red-colors-4) 25%,
        var(--pallete-yellow-red-colors-3) 49.7%,
        var(--pallete-yellow-red-colors-2) 75%,
        var(--pallete-yellow-red-colors-1) 100.12%
      ) !important;
    }

    &_active {
      background: none !important;
    }
  }

  &__range-label-container {
    background: rgba(25, 48, 82, 0.8);
    padding: 4px;

    @include regular;
    font-size: 14px;
    color: white;
    border-radius: 3px;
    top: -12px;
    position: relative;
    opacity: 1;
    visibility: visible;

    &_hidden {
      opacity: 0;
      visibility: hidden;
      transition: all .4s ease;
    }

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 100%;
      border-top: 5px solid rgba(25, 48, 82, 0.8);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      margin-left: -5px;
    }
  }
}

@media screen and (max-width: 800px) {
  .map-territory-filter {
    &__name {
      display: none;
    }
  }
}

@media screen and (max-width: 640px) {
  .map-territory-filter {
    &__range {
      flex-grow: 1;
      width: auto;
      min-width: auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .map-territory-filter {
    &__label {
      font-size: 12px;
    }
  }
}
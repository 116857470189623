@import "assets/styles/_mixins.scss";

.checkbox {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #BABABA;
  margin-right: 14px;
  flex-shrink: 0;
  
  &[aria-checked=mixed] {
    background: var(--blue-color);
    border-color: var(--blue-color);
  }
  &[aria-checked=true] {
    background: var(--blue-color);
    border-color: var(--blue-color);
  }
  &__checked {
    width: 13px;
    height: 10px;
    fill: white;
    pointer-events: none;
  }
  &__mixed {
    width: 10px;
    height: 2px;
    background: white;
    pointer-events: none;
  }
}


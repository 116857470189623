@import 'assets/styles/_mixins.scss';

.contacts {
  position: absolute;
  top: var(--header-height);
  bottom: 0;
  left: 0;
  right: 0;

  &__info {
    position: absolute;
    top: 30px;
    left: 30px;
    background: white;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    padding: 36px 30px 36px 36px;
    width: 460px;
  }

  &__info-title {
    @include regular;
    font-size: 36px;
    color: black;
    margin-bottom: 22px;
  }

  &__company-name {
    @include regular;
    font-size: 18px;
    color: black;
    margin-bottom: 22px;
  }

  &__info-row {
    @include regular;
    font-size: 14px;
    color: black;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__address-icon {
    width: 14px;
    height: 20px;
    fill: var(--blue-color);
    margin-right: 19px;
  }

  &__phone-icon {
    width: 18px;
    height: 18px;
    fill: var(--blue-color);
    margin-right: 17px;
  }

  &__mail-icon {
    width: 20px;
    height: 16px;
    fill: var(--blue-color);
    margin-right: 16px;
  }

  &__zoom-tools {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &__loading {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading-icon {
    fill: var(--blue-color);
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 800px) {
  .contacts {
    &__info {
      position: absolute;
      top: 15px;
      left: 15px;
      padding: 20px;
      width: 300px;
    }

    &__info-title {
      font-size: 24px;
    }

    &__company-name {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 400px) {
  .contacts {
    &__info {
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px;
      width: 100%;
    }

    &__info-title {
      font-size: 24px;
    }

    &__company-name {
      font-size: 16px;
    }
  }
}

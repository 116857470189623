@import 'assets/styles/_mixins.scss';

.areas-filter {
  $root: &;

  &__item {
    &_flex {
      display: flex;
      align-items: center;
    }
  }

  &__item-button {
    @include reset-button-styles;
    display: flex;
    width: 100%;
    height: 54px;
    padding: 0 16px;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    position: relative;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: #2c3a5a;

      #{$root}_dark & {
        background-color: #F5F5F5;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.1);
    }

    #{$root}__item:last-child & {
      &:after {
        display: none;
      }
    }

    &_justify {
      justify-content: space-between;
    }
  }

  &__item-left {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__item-icon {
    fill: var(--blue-color);
    margin-right: 20px;
  }

  &__item-names {
    overflow: hidden;
  }

  &__item-name {
    @include regular;
    font-size: 9px;
    letter-spacing: 0.5px;
    color: rgba(214, 214, 255, 0.5);
    text-transform: uppercase;

    #{$root}_dark & {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__item-desc {
    @include regular;
    font-size: 14px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    #{$root}_dark & {
      color: black;
    }
  }

  &__create-selection-button {
    @include reset-button-styles;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background: #31314d;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }

  &__cancel-terrs-button {
    @include reset-button-styles;
    margin: 19px 15px 15px;
  }
}

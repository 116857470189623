@import 'assets/styles/_mixins.scss';

.info-box {
  background: white;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.35);
  padding: 30px;
  width: 520px;
  position: relative;
  pointer-events: none;

  &__territory-name {
    @include regular;
    font-size: 24px;
    color: black;
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 17px;
    margin-right: 30px;
  }

  &__indicator-name {
    @include regular;
    font-size: 18px;
    color: black;
    margin-bottom: 16px;
  }

  &__close-button {
    @include reset-button-styles;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: visible;
  }

  &__close-icon {
    width: 20px;
    height: 20px;
    fill: #787878;
  }

  &__measure-indicator {
    width: 100%;
    height:10px;
  }

  &__measure-indicator-value {
   border-radius: 8px;
    height:10px;
  }

  &__fact, &__measure {
    @include regular;
    font-size: 14px;
    color: black;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__fact-value, &__measure-value {
    @include bold;
    font-size: 14px;
    color: black;
  }

  &__description {
    @include regular;
    font-size: 14px;
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 17px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 17px;

    &_no-border {
      border-top: none;
    }
  }

  &__select-container {
    padding-top: 17px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 17px;
    display: flex;
  }

  &__select-button {
    @include reset-button-styles;
    height: 36px;
    line-height: 36px;
    background: #4A90E2;
    border-radius: 3px;
    text-align: center;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include regular;
    font-size: 14px;
    color: white;
    pointer-events: visible;

    &:disabled {
      background: desaturate(#4A90E2, 45%);
      cursor: not-allowed;
    }

    @include button-hover;
  }

  &__select-text {
    margin-left: 20px;
    @include regular;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    line-height: 1.3;

    b {
      @include bold;
      text-transform: uppercase;
    }
  }
}

.map__map .mapboxgl-popup {
  max-width: max-content !important;
  z-index: 1;

  &-content {
    padding: 0;
  }

  &-close-button {
    @include reset-button-styles;
    z-index: 10;
    font-size: 46px;
    color: #787878;
    right: 20px;
    top: 24px;
    height: 30px;
    width: 30px;
    padding: 0;
    text-indent: -9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url('./icon-close.svg') no-repeat center;
    }
  }
}

@media screen and (max-width: 600px) {
  .info-box {
    padding: 15px;
    width: 280px;

    &__close-button {
      top: 15px;
      right: 15px;
    }

    &__territory-name {
      font-size: 18px;
      margin-right: 30px;
    }

    &__indicator-name {
      font-size: 16px;
    }

    &__select-container {
      flex-direction: column;
    }

    &__select-text {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}


@import 'assets/styles/_mixins.scss';

.exit-selection-mode {
  @include reset-button-styles;
  width: 32px;
  height: 32px;
  background: #31314D;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    fill: white;
  }
}

.zoom-tools {
  &__plus {
    background: var(--main-highlight-color);
    width: var(--control-button-size);
    height: calc(var(--control-button-size) + 3px);
    border-radius: var(--control-button-radius) var(--control-button-radius) 0 0;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &:hover {
      box-shadow: inset 0 0 var(--control-button-size) rgba(0,0,0,.5);
    }
    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
    }
    &:before {
      width: 11px;
      height: 2px;
    }
    &:after {
      width: 2px;
      height: 11px;
    }
  }

  &__minus {
    background: var(--main-highlight-color);
    width: var(--control-button-size);
    height: calc(var(--control-button-size) + 3px);
    border-radius: 0 0 var(--control-button-radius) var(--control-button-radius);
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      box-shadow: inset 0 0 40px rgba(0,0,0,.5);
    }
    &:before {
      content: "";
      display: block;
      width: 11px;
      height: 2px;
      background: white;
    }
  }
}

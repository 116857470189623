@import 'assets/styles/_mixins.scss';

.area-select {
  max-height: calc(100vh - 80px);
  overflow-y: auto;

  &__inner-container {
    padding: 40px 50px 120px;
  }

  &__title {
    @include regular;
    font-size: 24px;
    color: #1e1e1e;
    margin: 0 30px 20px 0;
  }

  &__select-all {
    display: flex;
    align-items: center;
    padding: 12px 20px;
  }

  &__not-full-note {
    padding: 20px;
    font-size: 14px;
    background: #ebebeb;
    color: #1e1e1e;
  }

  &__list {
  }
  &__item {
  }
  &__item-button {
    width: 100%;
    display: flex;
    background: #ebebeb;
    align-items: center;
    position: relative;
    padding: 12px 40px 12px 20px;
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;
    &:hover {
      background: #cbe4fb;
    }
    &_level-2 {
      padding-left: 56px;
      background: #f5f5f5;
    }
    &_level-3 {
      padding-left: 88px;
      background: white;
    }
    &_level-4 {
      padding-left: 121px;
      background: white;
    }
  }

  &__item-checkbox {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #bababa;
    margin-right: 14px;

    &[aria-checked='mixed'] {
      background: var(--blue-color);
      border-color: var(--blue-color);
    }

    &[aria-checked='true'] {
      background: var(--blue-color);
      border-color: var(--blue-color);
    }
  }

  &__item-checkbox-checked {
    width: 13px;
    height: 10px;
    fill: white;
    pointer-events: none;
  }

  &__item-checkbox-mixed {
    width: 10px;
    height: 2px;
    background: white;
    pointer-events: none;
  }

  &__item-name {
    @include regular;
    font-size: 14px;
    color: #1e1e1e;
  }

  &__item-expand {
    @include reset-button-styles;
    // display: flex;
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    // align-items: center;
    // justify-content: center;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 2px;
      top: 50%;
      left: 50%;
      background: rgba(30, 30, 30, 0.8);
      transform: translate(-50%, -50%);
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 12px;
      top: 50%;
      left: 50%;
      background: rgba(30, 30, 30, 0.8);
      transform: translate(-50%, -50%);
    }
    &_expanded {
      &:after {
        display: none;
      }
    }
  }
  &__item-load-more {
    @include reset-button-styles;
    height: 30px;
    width: 100%;
    background: var(--blue-color);
    text-align: center;
    line-height: 30px;
    color: white;
    @include regular;
    margin: 5px 0;
    &:hover {
      background: #4573bf;
    }
    &_level-2 {
      margin-left: 88px;
      width: calc(100% - 88px);
    }
    &_level-3 {
      margin-left: 121px;
      width: calc(100% - 121px);
    }
  }

  &__loading {
    text-align: center;
    padding: 10px;
  }
  &__loading-icon {
    fill: var(--blue-color);
    width: 20px;
    height: 20px;
  }

  &__apply {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px + 40px + 30px;
    padding: 30px 50px 0;
    background: white;
  }

  &__apply-button {
    @include reset-button-styles;
    height: 50px;
    width: 100%;
    background: var(--blue-color);
    color: white;
    font-size: 16px;
    @include regular;

    @include button-hover;
  }
}

@media screen and (max-width: 800px) {
  .area-select {
    &__inner-container {
      padding: 20px 20px 90px;
    }

    &__title {
      font-size: 18px;
    }

    &__item-button {
      padding: 8px 40px 8px 20px;
      &_level-2 {
        padding-left: 36px;
      }
      &_level-3 {
        padding-left: 56px;
        background: white;
      }
      &_level-4 {
        padding-left: 84px;
        background: white;
      }
    }

    &__apply {
      height: 50px + 20px + 20px;
      padding: 20px 20px 0;
      background: white;
    }
  }
}

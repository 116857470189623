@import 'assets/styles/_mixins.scss';

.territories-filters-select {
  padding: 40px 50px 38px;

  &__title {
    font-size: 24px;
    color: #1e1e1e;
    margin-bottom: 20px;
    @include regular;
  }

  &__items {
    max-height: 250px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #FFFFFF;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .3);
      border-radius: 2px;
    }
  }

  &__item-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    min-height: 50px;

    padding: 11px 0 11px 20px;

    &_dark {
      background-color: #EBEBEB;
      border-bottom: 1px solid #E6E6E6;

      &:hover {
        background-color: #CBE4FB;
      }
    }
  }

  &__item-checkbox {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #bababa;
    margin-right: 14px;
  }

  &__item-name {
    @include regular;
    font-size: 14px;
    color: #1e1e1e;
  }

  &__buttons {
    margin-top: 30px;

    display: flex;
    justify-content: space-between;
  }

  &__clear-button {
    @include reset-button-styles;
    height: 50px;
    width: 70%;
    background: #FFFFFF;
    color: var(--blue-color);
    font-size: 14px;
    @include regular;
    border: 1px solid var(--blue-color);
    border-radius: 3px;
    margin-right: 20px;
  }

  &__apply-button {
    @include reset-button-styles;
    height: 50px;
    width: 100%;
    background: var(--blue-color);
    color: white;
    font-size: 14px;
    @include regular;
    border-radius: 3px;

    @include button-hover;
  }
}
@import 'assets/styles/_mixins.scss';

.package-select {
  display: flex;
  &__left {
    background: #F5F5F5;
    width: 220px;
  }

  &__left-title {
    @include regular;
    font-size: 18px;
    color: #1E1E1E;
    margin: 40px 0 40px 30px;
  }
  &__root-list {
    width: 100%;
  }
  &__root-item {
    width: 100%;
  }
  &__root-button {
    width: 100%;
    text-align: left;
    padding: 15px 30px;
    @include regular;
    line-height: 20px;
    font-size: 14px;
    color: var(--blue-color);
    background: none;
    border: none;
    cursor: pointer;
    .package-select__root-item_active & {
      background: white;
      color: black;
    }

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  &__right {
    padding: 40px 50px 50px;
    width: 593px;
  }

  &__title {
    @include regular;
    font-size: 24px;
    color: #1e1e1e;
    margin-bottom: 20px;
  }

  &__list {
  }
  &__item {
  }
  &__item-button {
    width: 100%;
    min-height: 50px;
    display: flex;
    background: #EBEBEB;
    align-items: center;
    position: relative;
    padding: 15px 20px;
    border: none;
    border-bottom: 1px solid #E6E6E6;
    cursor: pointer;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background: #cbe4fb;
    }
    &_level-2 {
      padding-left: 56px;
      background: white;
    }
  }
  &__icon-checkbox {
    fill: var(--blue-color);
    position: absolute;
    left: 24px;
  }
  &__item-name {
    @include regular;
    font-size: 14px;
    color: #1e1e1e;
    text-align: left;
  }
  &__item-expand {
    @include reset-button-styles;
    display: flex;
    position: absolute;
    right: 21px;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 2px;
      background: rgba(30, 30, 30, 0.8);
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 12px;
      background: rgba(30, 30, 30, 0.8);
    }
    &_expanded {
      &:after {
        display: none;
      }
    }
  }

  &__loading {
    text-align: center;
    padding: 10px;
    margin: 60px;
    width: 100%;
  }
  &__loading-icon {
    fill: var(--blue-color);
    width: 20px;
    height: 20px;
  }
  &__error {
    text-align: center;
    color: #E20C28;
    padding: 20px;
    margin: 60px;
  }
}

@media screen and (max-width: 800px) {
  .package-select {
    &__left {
      min-width: 150px;
      max-width: 150px;
    }

    &__left-title {
      font-size: 16px;
      margin: 30px 0 30px 20px;
    }

    &__root-list {
      width: 100%;
    }

    &__root-item {
      width: 100%;
    }
    &__root-button {
      padding: 10px 20px;
    }

    &__right {
      flex-grow: 20;
      padding: 30px 0 20px 20px;
      width: auto;
      overflow: hidden;
    }

    &__title {
      font-size: 18px;
      padding-right: 30px;
    }

    &__item-button {
      height: auto;
      padding: 8px 15px;
      border: none;
      white-space: normal;

      &_level-2 {
        padding-left: 24px;
      }
    }

    &__icon-checkbox {
      left: 0;
    }

    &__item-name {
      white-space: normal;
      text-align: left;
    }
  }
}

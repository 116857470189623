@import "assets/styles/_mixins.scss";

.ratings {
  &__nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
  }

  &__nav-bar-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__nav-bar-button {
    @include reset-button-styles;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 20px;
    margin-right: 10px;

    &:only-child {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__back-icon {
    fill: var(--blue-color);
    width: 18px;
    height: 12px;
  }

  &__export-icon {
    fill: var(--blue-color);
    width: 22px;
    height: 16px;
  }

  &__full-screen-icon {
    fill: var(--blue-color);
    width: 18px;
    height: 15px;
  }

  &__title {
    @include regular;
    font-size: 18px;
    line-height: 1.44;
    color: black;
    margin-bottom: 20px;
  }

  &__ratings {
    margin-bottom: 20px;
  }

  &__ratings-item {
    margin-bottom: 10px;
  }

  &__ratings-item-name {
    @include regular;
    font-size: 14px;
    color: #2B2B44;
    margin-bottom: 5px;
  }

  &__ratings-item-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__ratings-item-bar {
    height: 30px;
    background: #0781B1;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    align-items: center;

    &_small {
      margin-right: 10px;
    }
  }

  &__ratings-item-rank {
    @include regular;
    font-size: 14px;
    color: white;

    &_dark {
      color: #0781b1;;
      margin-right: 10px;
    }
  }

  &__ratings-item-value {
    @include regular;
    font-size: 14px;
    color: white;

    &_dark {
      color: #2B2B44;
    }
  }
}

@import 'assets/styles/_mixins.scss';

.period-select-trigger {
  position: relative;

  &__button {
    @include reset-button-styles;
    display: flex;
    width: 100%;
    height: 54px;
    padding: 0 16px;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    position: relative;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background: rgba(0, 0, 0, 0.05);
    }

    &_justify {
      justify-content: space-between;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__icon {
    fill: var(--blue-color);
    margin-right: 20px;
  }

  &__name {
    @include regular;
    font-size: 9px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
  }

  &__desc {
    @include regular;
    font-size: 14px;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__reset-button {
    @include reset-button-styles;
    position: absolute;
    right: 6px;
    top: 50%;
    width: 25px;
    height: 25px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &__reset-icon {
    fill: var(--blue-color);
    width: 13px;
    height: 13px;
  }
}

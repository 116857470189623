@import 'assets/styles/_mixins.scss';

.auth-actions {
  margin-top: 30px;

  &__block {
    display: flex;
    justify-content: space-between;
  }

  &__button {
    @include reset-button-styles;
    height: 40px;
    background-color: #4A90E2;
    border-radius: 3px;
    padding: 0 40px;
    line-height: 40px;
    font-size: 14px;
    color: white;

    @include regular;
    cursor: pointer;

    &:disabled {
      background: #c0c3c4;
      cursor: not-allowed;
    }
  }

  &__link {
    @include reset-button-styles;
    @include regular;
    line-height: 20px;
    font-size: 14px;

    color: #4A90E2;
    cursor: pointer;
    padding: 10px 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@import 'assets/styles/_mixins.scss';

.map-component {
  flex-grow: 20;
  position: relative;
  width: 50%;

  &:after {
    content: "";
    background: #242438;
    width: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  &:only-child {
    width: 100%;
    height: 100%;
  }

  &__export-substrate {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}

.mapboxgl-canvas {
  // width: 100% !important;
  // height: 100% !important;
}

.map__map {
  .mapboxgl-ctrl-group {
    box-shadow: 0 4px 10px rgba(66, 69, 76, 0.5);
    border-radius: var(--control-button-radius);
    position: relative;
    width: var(--control-button-size);
    background: var(--main-highlight-color);

    > button {
      width: var(--control-button-size);
      height: var(--control-button-size);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      border-radius: var(--control-button-radius);
      cursor: pointer;
      position: relative;
      background-image: none;

      &:hover {
        box-shadow: inset 0 0 var(--control-button-size) rgba(0, 0, 0, 0.5);
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 20;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    > .mapboxgl-ctrl-fullscreen {
      &:before {
        background-image: url('../../../../../../assets/icons/icon-full-screen.svg');
      }
    }

    > .mapboxgl-ctrl-shrink {
      &:before {
        background-image: url('../../../../../../assets/icons/icon-full-screen-exit.svg');
      }
    }

    > .mapboxgl-ctrl-zoom-in {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
      }
      &:before {
        width: 11px;
        height: 2px;
      }
      &:after {
        width: 2px;
        height: 11px;
      }
    }

    > .mapboxgl-ctrl-zoom-out {
      &:before {
        content: '';
        position: absolute;
        width: auto;
        left: 6px;
        right: 6px;
        background: rgba(255, 255, 255, 0.15);
        height: 1px;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 11px;
        height: 2px;
        background: white;
      }
    }
  }

  .mapboxgl-ctrl-attrib-inner {
    height: 28px;
    line-height: 28px;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact {
    padding-top: 4px;
    margin-bottom: -2px;
    margin-right: 15px;
  }

  .mapboxgl-ctrl-scale {
    position: relative;
    text-align: center;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px 3px 0 0;
    height: 28px;
    border: none;
    @include regular;
    font-size: 14px;
    line-height: 28px;
    color: #16273f;
    border-bottom: 2px #193052 solid;

    @media screen and (max-width: 500px) {
      position: absolute;
      left: 100px;
      bottom: 0;
      margin: 0 0 5px 0;
    }
  }

  .mapboxgl-ctrl-bottom-right {
    .mapboxgl-ctrl-group {
      margin: 0 20px 20px 0;

      @media screen and (max-width: 500px) {
        margin: 0 10px 10px 0;
      }
    }
  }
}

@import 'assets/styles/_mixins.scss';

.dates-range {
  &__datepickers-wrapper {
    display: flex;
    .react-datepicker {
      &__input-container {
        input {
          padding: 0 12px;
          height: 37px;
          font-size: 14px;
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 3px;
        }
      }
    }
  }

  &__datepicker {
    display: flex;
    position: relative;
    //width: 118px;
    flex-grow: 20;
  }

  &__delimiter {
    width: 34px;
    min-width: 34px;
    display: flex;
  }

  &__delimiter-icon {
    margin: auto;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
  }

  &__save-button {
    width: calc(50% - 17px);
    height: 40px;
    font-size: 14px;
    color: white;
    background-color: var(--blue-color);
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid var(--blue-color);
    @include button-hover;
  }

  &__reset-button {
    width: calc(50% - 17px);
    height: 40px;
    font-size: 14px;
    color: var(--blue-color);
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid var(--blue-color);
  }
}

@media screen and (max-width: 800px) {
  .dates-range {
    &__datepickers-wrapper {
      flex-direction: column;

      .react-datepicker {
        &__input-container {
          input {
            padding: 0 12px;
            height: 37px;
            font-size: 14px;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 3px;
          }
        }
      }
    }

    &__datepicker {
      width: 118px;
      margin-bottom: 20px;
    }

    &__delimiter {
      margin: 0 0 20px 0;
      width: 118px;
    }
  }
}

@import "assets/styles/_mixins.scss";

.interval-select {
  &__name {
    @include regular;
    font-size: 14px;
    color: black;
    margin-bottom: 14px;
  }

  &__radio-button-group {
    display: flex;
    width: 100%;
  }

  &__radio-button {
    @include reset-button-styles;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 38px;
    width: 33.333%;

    text-align: center;

    @include regular;
    font-size: 14px;
    line-height: 20px;
    //color: rgba(30, 30, 30, 0.4);
    color: rgba(30, 30, 30, 1);

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:nth-child(n + 2) {
      border-left: none;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &[aria-checked=true] {
      background: rgba(74, 144, 226, 0.3);
      color: rgba(30, 30, 30, 1);
    }
  }
}

@import '_mixins.scss';
:root {
  --main-highlight-color: #242438;
  --control-button-size: 40px;
  --control-button-radius: 3px;
  --blue-color: #4a90e2;
  --header-height: 70px;
}

* {
  box-sizing: border-box;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @include regular;
}

html body {
  .ymaps-2-1-73-inner-panes:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #131336;
    z-index: 1004;
    opacity: 0.4;
    mix-blend-mode: color-dodge;
    pointer-events: none;
  }

  .ymaps-2-1-73-ground-pane {
    filter: grayscale(1);
  }
}

// ipad fix: поведение с навбаром safari
@media screen and (max-width: 1024px) {
  body {
    overflow: hidden;
  }
}
.auth {
  &__block {
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // display: flex;
  }

  &__window {
    padding: 40px;
    background-color: #FFFFFF;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    width: 100%;

    @media screen and (max-width: 800px) {
      width: 100%;
      box-shadow: none;
      padding: 80px 20px 20px;
      margin-top: 0;
    }
  }
}

@import 'assets/styles/_mixins.scss';

.auth {
  &__mail,
  &__password {

    &-description {
      @include regular();
      font-size: 14px;
      margin-bottom: 4px;
    }

    &-input {
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 3px;
      padding: 0 10px;
      width: 100%;
      @include regular();
      line-height: normal;
      font-size: 14px;
      height: 40px;
    }

    &-input[type=text] + &-input-icon path {
      fill: #4A90E2;
    }

    &-input-error {
      border-color: #E20C28;
    }

    &-input-wrapper {
      margin-top: 10px;
      position: relative;
    }

    &-input-toggle-button {
      @include reset-button-styles;
      position: absolute;
      right: 20px;
      bottom: calc(50% - 20px/2);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &-input-toggle-icon {
      .auth__password-input-toggle-button:hover & {
        path {
          fill: #87C4FF;
        }
      }
    }

    &-validation-failed {
      position: absolute;
      right: 10px;
      bottom: calc(50% - 18px/2);
    }
  }
}
